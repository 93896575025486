var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "facility-config-index-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("图表配置")])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-sider",
                { staticClass: "page-menu-sider", attrs: { theme: "light" } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "图表配置分组", bordered: false } },
                    [
                      _c(
                        "a-menu",
                        {
                          attrs: {
                            "selected-keys": [_vm.$route.name],
                            theme: "light"
                          }
                        },
                        [
                          _c(
                            "a-menu-item-group",
                            { attrs: { title: "综合安防" } },
                            [
                              _c(
                                "a-menu-item",
                                { key: "watch-camera" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to:
                                          "/dashboard/facility-config/watch-camera"
                                      }
                                    },
                                    [_vm._v("展示镜头")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-menu-item-group",
                            { attrs: { title: "通行管理" } },
                            [
                              _c(
                                "a-menu-item",
                                { key: "access-config" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to:
                                          "/dashboard/facility-config/access-config"
                                      }
                                    },
                                    [_vm._v("通行配置")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("a-layout-content", [_c("router-view")], 1)
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }